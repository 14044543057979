import { useRef, useState, useEffect } from "react";
import "./YearInReviewAnimation.scss";
import { FlapDisplay, Presets } from "react-split-flap-effect";
import "./themes.css";
import Draggable from "react-draggable";
import * as htmlToImage from "html-to-image";
import download from "downloadjs";
import Modal from "react-modal";
import { isMobile } from "react-device-detect";


const factor = 0.66;

const innerWidth = window.innerWidth;
const innerHeight = window.innerHeight;

const url = window.location.href;

function useWindowDimensions() {
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function YearInReviewAnimation(props) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [languageModal, setLanguageModal] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const { width, height } = useWindowDimensions();
  const aspectRatio = width / height;

  let widthScaleFactor = 1;

  if (aspectRatio > 0.6) {
    widthScaleFactor = Math.min(1.5, Math.max(0.5, height / 900));
  } else {
    widthScaleFactor = Math.min(1.5, Math.max(0.5, width / 500));
  }

  const {
    general,
    slide0,
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
    shareJourney
  } = props;

  const dragRef = useRef();

  useEffect(() => {
    Modal.setAppElement("#root-yearinreview");

    setTimeout(() => {
      setLoaded(true);
    }, 5000);
  }, []);

  const printRef = useRef();

  const [openShutter, setOpenShutter] = useState(true);
  const [dynamicShutterTop, setDynamicShutterTop] = useState(-528);

  const [printing, setPrinting] = useState(false);

  const [bgImage, setBgImage] = useState(`${slide1.backgroundImage}`);

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  const [modalIsOpen, setIsOpen] = useState(false);

  const [languageModalIsOpen, setlanguageModalIsOpen] = useState(false);


  const [generatedSlideImages, setGeneratedSlideImages] = useState([]);
  const [newSlideImage, setNewSlideImage] = useState(null);
  const [selectedSlideImages, setSelectedSlideImage] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const [currentSlideUserOn, setCurrentSlideUserOn] = useState(null);

  const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);


  useEffect(() => {
    const handleOrientationChange = (event) => {
      setIsPortrait(event.matches);
    };

    const portraitMediaQuery = window.matchMedia("(orientation: portrait)");
    portraitMediaQuery.addEventListener('change', handleOrientationChange);

    // Cleanup listener on unmount
    return () => {
      portraitMediaQuery.removeEventListener('change', handleOrientationChange);
    };
  }, []);


  //pageview tracking
  useEffect(() => {

   if(scrollPosition){
     if(scrollPosition > 5 && scrollPosition < 4345){
       if(currentSlideUserOn != 0){
        setCurrentSlideUserOn(0);
       }
     }

     if(scrollPosition > 4345 && scrollPosition < 8295){
       if(currentSlideUserOn != 1){
        setCurrentSlideUserOn(1);
       }
     }

     if(scrollPosition > 8295 && scrollPosition < 12400){
       if(currentSlideUserOn != 2){
        setCurrentSlideUserOn(2);
       }
     }

     if(scrollPosition > 12400 && scrollPosition < 16400){
       if(currentSlideUserOn != 3){
        setCurrentSlideUserOn(3);
       }
     }

     if(scrollPosition > 16400 && scrollPosition < 20145){
       if(currentSlideUserOn != 4){
        setCurrentSlideUserOn(4);
       }
     }

     if(scrollPosition > 20145 && scrollPosition < 24095){
       if(currentSlideUserOn != 5){
        setCurrentSlideUserOn(5);
       }
     }

     if(scrollPosition > 24095 && scrollPosition < 28045){
       if(currentSlideUserOn != 6){
        setCurrentSlideUserOn(6);
       }
     }

     if(scrollPosition > 28045 && scrollPosition < 33000){
       if(currentSlideUserOn != 7){
        setCurrentSlideUserOn(7);
       }
     }

     if(scrollPosition > 33000){
       if(currentSlideUserOn != 8){
        setCurrentSlideUserOn(8);
       }
     }
   }
 }, [scrollPosition, currentSlideUserOn]);

//call onPageView on currentSlideUserOn changes
 useEffect(() => {
  general.onPageView(`SLIDE_${currentSlideUserOn}`);
 }, [currentSlideUserOn,general]);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  
  const openLanguageModal = () => {
    setlanguageModalIsOpen(true);
  };

  const closeLanguageModal = () => {
    setLanguageModal(false);
    setlanguageModalIsOpen(false);
  };

  const handleResize = () => {
    setViewportWidth(window.innerWidth);
  };

  useEffect(() => {
    if (generatedSlideImages) {
      console.log(
        "generatedSlideImages --- from useEffect:",
        generatedSlideImages
      );
    }

    if (selectedSlideImages) {
      console.log("setSelectedSlideImage -- ", selectedSlideImages);
    }
    // openModal();
    console.log("width", width);
    // setDynamicShutterTop(returnWhenUnderBreakpoint(width, height));
    setTimeout(() => {
      setLoaded(true);
    }, 3000);
    setViewportWidth(window.innerWidth);
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width, generatedSlideImages, selectedSlideImages]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const position = window.scrollY;
  //     console.log(position);
  //     setScrollPosition(position);
  //   };
  //   document
  //     .querySelector("#ww")
  //     .addEventListener("scroll", handleScroll, { passive: true });
  //   return () => {
  //     document.querySelector("#ww").removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  //need to find a library that can replace this useEffect, but hopefully for now if it only
  //handles the inverted parallax, it should keep things under control.

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const screenshotsTypeTwo = async () =>
    // htmlToImage
    //   .toJpeg(document.getElementById("SLIDE_0"), { quality: 0.95 })
    //   .then(function (dataUrl) {
    //     var link = document.createElement("a");
    //     link.download = "cx-yr-in-review-0.jpeg";
    //     link.href = dataUrl;
    //     link.click();
    //   });

    {
      general.onPageView("SHARE_JOURNEY");

      setGeneratedSlideImages([]);

      setPrinting(true);

      console.log("printing...");
      console.log("the width of the view port is..", viewportWidth);

      var shareAPI = false;

      let shareData = {
        files: [],
        title: "My Journey",
        text: "",
      };

      // navigator.share(data)
      
      // if (navigator.canShare && viewportWidth < 601) {

      if(isMobile){
        console.log('its on mobile...');
      }
      if (navigator.canShare && isMobile) {
        console.log(
          "the browser supports share and user is on a mobile device"
        );
        shareAPI = true;
        console.log("can share now....");
      }

      // shareAPI = true; //manually change this for testing
      const rootElement = document.getElementById("root-yearinreview");
      if (rootElement) {
        // Add the desired class
        rootElement.classList.add("printing");
      }
      console.log("printing...after rootElement");
      const noOfSlides = 10;
      // await delay(2000);

      for (let ind = 1; ind < noOfSlides; ind++) {
        // const element = array[index];
        console.log("inside the loop");
        console.log(ind);
        // await delay(1000);
        console.log("indx is: ", ind);
        if (ind == 9) {
          console.log("indx is: ", ind);
          // return;
          break; //only exit the loop
        }

        var PRINT_JOURNEY = document.getElementById("PRINT_JOURNEY");

        // Set the background image
        // if(PRINT_JOURNEY){
        // PRINT_JOURNEY.style.backgroundImage =
        console.log("able to get the element PRINT_JOURNEY");
        if (ind == 1) {
          setBgImage(slide1.backgroundImage);
          document.getElementById(`SLIDE_1_PRINT`).classList.add("active");
        }
        if (ind == 2) {
          setBgImage(slide2.backgroundImage);
        }
        if (ind == 3) {
          setBgImage(slide3.backgroundImage);
        }
        if (ind == 4) {
          setBgImage(slide4.backgroundImage);
        }
        if (ind == 5) {
          setBgImage(slide5.backgroundImage);
        }
        if (ind == 6) {
          setBgImage(slide6.backgroundImage);
        }
        if (ind == 7) {
          setBgImage(slide7.backgroundImage);
        }
        if (ind == 8) {
          setBgImage(slide8.backgroundImage);
        }
        // }
        if(ind == 1){
          if (shareAPI) {
            await delay(1000);
          } else {
            await delay(1000);
          }
        }

        console.log("before..generatedSlideImages");
        console.log(generatedSlideImages);

        await htmlToImage
        // .toPng(document.getElementById("SLIDE_0_PRINT"))
        .toBlob(document.getElementById("PRINT_JOURNEY"))
        .then(function (dataUrl) {
          var file = new File(
            [dataUrl],
            `cx-year-in-review-2024-${ind}.png`,
            {
              type: dataUrl.type,
            }
          );

          console.log(file);

          shareData.files.push(file);
          // setNewSlideImage(`slide${ind}.png`);
          // setNewSlideImage(file);
          // setNewSlideImageHelper(`slide${ind}.png`);

          // console.log('the new slide image is...');
          // console.log(newSlideImage);
          // setNewSlideImage(null);
        });
      // await delay(500);
      // console.log('the new slide image is...');
      // console.log(newSlideImage);
      // setGeneratedSlideImages([...generatedSlideImages, newSlideImage]);
      // setNewSlideImage(null);

        document
          .getElementById(`SLIDE_${ind}_PRINT`)
          .classList.remove("active");
        //  if(ind == 7){
        //   await delay(2000);
        //  }
        if (ind < 8) {
          document
            .getElementById(`SLIDE_${ind + 1}_PRINT`)
            .classList.add("active");
        } else {
          document.getElementById(`SLIDE_1_PRINT`).classList.add("active");
        }

        // if (shareAPI) {
        //  await htmlToImage
        //     // .toPng(document.getElementById("SLIDE_0_PRINT"))
        //     .toBlob(document.getElementById("PRINT_JOURNEY"))
        //     .then(function (dataUrl) {
        //       var file = new File(
        //         [dataUrl],
        //         `cx-year-in-review-2024-${ind}.png`,
        //         {
        //           type: dataUrl.type,
        //         }
        //       );

        //       console.log(file);

        //       shareData.files.push(file);
        //       // setNewSlideImage(`slide${ind}.png`);
        //       // setNewSlideImage(file);
        //       // setNewSlideImageHelper(`slide${ind}.png`);

        //       // console.log('the new slide image is...');
        //       // console.log(newSlideImage);
        //       // setNewSlideImage(null);
        //     });
        //   // await delay(500);
        //   // console.log('the new slide image is...');
        //   // console.log(newSlideImage);
        //   // setGeneratedSlideImages([...generatedSlideImages, newSlideImage]);
        //   // setNewSlideImage(null);

        //   document
        //     .getElementById(`SLIDE_${ind}_PRINT`)
        //     .classList.remove("active");
        //   //  if(ind == 7){
        //   //   await delay(2000);
        //   //  }
        //   if (ind < 8) {
        //     document
        //       .getElementById(`SLIDE_${ind + 1}_PRINT`)
        //       .classList.add("active");
        //   } else {
        //     document.getElementById(`SLIDE_1_PRINT`).classList.add("active");
        //   }
        // } else {
        //  await htmlToImage
        //     // .toPng(document.getElementById("SLIDE_0_PRINT"))
        //     .toPng(document.getElementById("PRINT_JOURNEY"))
        //     .then(function (dataUrl) {
        //       //print slide 0
        //       //  download(dataUrl, `slide${index}.png`);
        //       download(dataUrl, `cx-year-in-review-2024-${ind}.png`);
        //     });
        //   // await delay(500);

        //   document
        //     .getElementById(`SLIDE_${ind}_PRINT`)
        //     .classList.remove("active");
        //   //  if(ind == 7){
        //   //   await delay(2000);
        //   //  }
        //   if (ind < 8) {
        //     document
        //       .getElementById(`SLIDE_${ind + 1}_PRINT`)
        //       .classList.add("active");
        //   } else {
        //     document.getElementById(`SLIDE_1_PRINT`).classList.add("active");
        //   }
        // }
      } //end of for loop

      console.log("files for sharing:");
      console.log(shareData);

      // if (shareAPI) {
      //   setGeneratedSlideImages(shareData.files);
      //   console.log("generated images");

      //   // await delay(3000);
      //   console.log(generatedSlideImages);
      //   openModal();
      // }

      setGeneratedSlideImages(shareData.files);
        console.log("generated images");

        // await delay(3000);
        console.log(generatedSlideImages);
        openModal();
      // if (shareAPI) {
      //   try {
      //     // const userConfirmed =  window.confirm("You Journey is ready!, Would you like to share?");
      //     // if (userConfirmed) {
      //     //     // User clicked "OK"
      //     //    await navigator.share(shareData);

      //     //     console.log("User confirmed the action.");
      //     // } else {
      //     //     // User clicked "Cancel"
      //     //     console.log("User cancelled the action.");
      //     // }

      //     // if (shareData.files.length > 7) {
      //     //   await navigator.share(shareData);
      //     // } else {
      //     //   console.log("couldnt generate all the 8 files");
      //     // }

      //     setGeneratedSlideImages(shareData.files);
      //     console.log('generated images');

      //     // await delay(3000);
      //     console.log(generatedSlideImages);
      //     openModal();
      //   } catch (error) {
      //     console.log("something went wrong when attempting to share..");
      //     console.log(error);
      //     //if share API fails then download the images
      //     shareData.files.map((item, index) =>
      //       // <li key={index}>{item}</li>
      //       download(item, `slide${index + 1}.png`)
      //     );
      //   }
      // }
      rootElement.classList.remove("printing");
      setPrinting(false);
    };

  const handleCheckboxChange = (index, event) => {
    if (event.target.checked) {
      console.log("item is checked");

      if (!selectedSlideImages.includes(index)) {
        setSelectedSlideImage((selectedSlideImages) => [
          ...selectedSlideImages,
          index,
        ]);
      }
    } else {
      console.log("item is unchecked");
      if (selectedSlideImages.includes(index)) {
        setSelectedSlideImage((selectedSlideImages) =>
          selectedSlideImages.filter((item) => item !== index)
        );
      }
    }
  };

  const reportSharedOrDownloadedSlides = async () => {
    let selectedSlideNamesArray = [];

    selectedSlideImages.forEach(element => {
      selectedSlideNamesArray.push(`SLIDE_${element}`);
    });
    console.log('downloaded / shared slides: ', selectedSlideNamesArray);
    shareJourney.onShareJourneySlides(selectedSlideNamesArray);
  }

  const shareYourJourneyButton = () => {
    let canShare = false;
    if (navigator.canShare && isMobile) {
      canShare = true;
    }

    console.log('canShare?',canShare);

    if (canShare) {
      let shareData = {
        files: [],
        title: "My Journey",
        text: "",
      };

      generatedSlideImages.forEach((img, indx) => {
        if (selectedSlideImages.includes(indx)) {
          shareData.files.push(generatedSlideImages[indx]);
        }
      });

      navigator
        .share(shareData)
        .then(() => {
          console.log("share was successful!");
          closeModal();
          reportSharedOrDownloadedSlides();
        })
        .catch((error) => {
          console.log(error);

          setToastMessage(error.toString());
          setShowToast(true);
      
          if(!error.toString().includes('AbortError')){
            //if share didnt work and user didn't cancel share -> then allow download images
            generatedSlideImages.forEach((img, indx) => {
              if (selectedSlideImages.includes(indx)) {
                download(img, `cx-year-in-review-2024-${indx}.png`);
              }
            });
            reportSharedOrDownloadedSlides();
          }
          //dismiss toast
          setTimeout(() => {
            setShowToast(false);
            setToastMessage('')
          }, 3000);

          closeModal();
          
        });
    }else{
      generatedSlideImages.forEach((img, indx) => {
        if (selectedSlideImages.includes(indx)) {
          // shareData.files.push(generatedSlideImages[indx]);
          download(img, `cx-year-in-review-2024-${indx}.png`);
        }
      });
      closeModal();
      reportSharedOrDownloadedSlides();
    }
  };

 const copyToClip = async () => {
    await navigator.clipboard.writeText(window.location.href);
    console.log("Copied");
    setToastMessage("Copied");
    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
      setToastMessage('')
    }, 3000);
  } 
  

  return (
    <>
      <div className="main-parallax-wrapper">
        <div
          className="main-parallax"
          style={{
            top: `calc(-730vh + ${scrollPosition * 0.1 * (height / 600)}px`, //multiply scrollPosition by a factor to alter scroll rate and create parallax effect
          }}
        />
        <div className="blur blur-l" />
        <div className="blur blur-r" />
        <div className="blur blur-t" />
        <div className="blur blur-b" />
      </div>

      <div className="cloud-parallax-wrapper">
        <div
          className="clouds_3"
          style={{
            top: `${-2000 - scrollPosition * 0.1 * (height / 600)}px`, //multiply scrollPosition by a factor to alter scroll rate and create parallax effect
          }}
        />
        <div
          className="clouds_2"
          style={{
            top: `${100 - scrollPosition * 0.05 * (height / 600)}px `, //multiply scrollPosition by a factor to alter scroll rate and create parallax effect
          }}
        />
      </div>

      <div
        className="window-frame-wrapper"
        style={{
          transform: `translate(-50%, -50%) ${
            widthScaleFactor && `scale(${widthScaleFactor})`
          }`,
        }}
      >
        <div tabIndex="0" 
        // style={{width: "100px",height: "100px"}}
          role="link"><img src={general.cathayLogo} alt="logoooo"/></div>
        {general.membershipTier === "GR" && (
          <img src={general.windowFrameGreenImgUrl} />
        )}
        {general.membershipTier === "SL" && (
          <img src={general.windowFrameSilverImgUrl} />
        )}
        {general.membershipTier === "GO" && (
          <img src={general.windowFrameGoldImgUrl} />
        )}
        {general.membershipTier === "DM" && (
          <img src={general.windowFrameDiamondImgUrl} />
        )}

        <div className="shutter-draggable-wrapper">
          <Draggable
            nodeRef={dragRef}
            axis="y"
            bounds={{ top: -435, bottom: 0 }}
            position={{ y: Math.max(-(scrollPosition * 0.2), -450), x: 0 }}
          >
            <div ref={dragRef} className="shutter-wrapper">
              <img src={general.shutterImgUrl} draggable={false} />
            </div>
          </Draggable>
        </div>

        <div
          id="ww"
          className="wrapper-wrapper"
          onScroll={(e) => {
            const { scrollTop } = e.target;
            const position = scrollTop;
            setScrollPosition(position);
          }}
        >
          <div id="journey" className="journey-parallax-wrapper">
            <div className="journey-parallax">
              <div
                id="SLIDE_0"
                className={`slide slide_0 ${
                  scrollPosition < 3950
                    ? "sticky"
                    : scrollPosition > 3555
                    ? "scrolledpast"
                    : ""
                }`}
              >
                {/* {!loaded && (
                  <div className="loading">
                    <div className="loading-spinner-outer">
                      <div className="loading-spinner-inner" />
                    </div>
                  </div>
                )} */}

                {loaded && (
                  <>
                    <span className="medium"></span>
                    <span
                      className="large vvnarrow wrap"
                      style={{ marginBottom: "-10px" }}
                    >
                      {slide0.landingTop}
                      <br />
                    </span>
                    <span className="large vvnarrow wrap">
                      {slide0.landingTitle}
                    </span>
                    <span className="small wrap">
                      {slide0.landingScrollLabel}
                    </span>
                    <div className="arrowanim">
                      <img src={slide0.arrowDownIcon} />
                    </div>
                  </>
                )}
              </div>
              <div
                id="SLIDE_1"
                className={`slide slide_1 ${
                  scrollPosition > 4345 && scrollPosition < 7500
                    ? "sticky"
                    : scrollPosition > 7500
                    ? "scrolledpast"
                    : ""
                }`}
              >
                <span className="medium">{slide1.memberYearDescription}</span>
                <div className="split-flap">
                  <FlapDisplay
                    className="XL"
                    chars={Presets.NUM}
                    length={slide1.memberYearCount.length}
                    value={
                      scrollPosition > 5000
                        ? slide1.memberYearCount.toString()
                        : "0"
                    }
                    timing={30}
                    hinge
                  />
                  <span className="sf-medium">
                    {slide1.memberYearUnit}
                    {slide1.memberYearUnit === "year" ? "s" : ""}
                  </span>
                </div>
              </div>
              <div
                id="SLIDE_2"
                className={`slide slide_2 ${
                  scrollPosition > 8295 && scrollPosition < 11850
                    ? "sticky"
                    : scrollPosition > 11455
                    ? "scrolledpast"
                    : ""
                }`}
              >

                  {/* FLIGHT ICON IS BROKEN */}
                  <img className="flight-icon" src={slide2.flightIcon} />
                  <span className="medium">{slide2.flightTopLine1}</span>
                  <span className="medium">{slide2.flightTopLine2}</span>
                  <div className="factoid col">
                    <span className="xlarge">{slide2.flightTimes}</span>
                    <span className="sf-medium">{slide2.flightUnit}</span>
                    {Number(slide3.miles) === 0 && (
                      <span className="sf-medium">{slide2.flightBottom}</span>
                    )}
                  </div>
                  <div className="runway-wrapper">
                    <img className="road" src={slide2.roadIcon} />
                    <img className="divider" src={slide2.roadDividerIcon} />
                  </div>
              </div>
              <div
                id="SLIDE_3"
                className={`slide slide_3 ${
                  scrollPosition > 12400 && scrollPosition < 15800
                    ? "sticky"
                    : scrollPosition > 15405
                    ? "scrolledpast"
                    : ""
                }`}
              >
                {Number(slide3.miles) === 0 && (
                  <>
                    {/* <img src={slide3.earthIcon} /> */}
                    <span className="medium">{slide3.miles0Top}</span>
                    <div className="factoid narrow">
                      <span className="large">{slide3.miles0Fact}</span>
                    </div>
                    
                    <div>
                      <div className="or-wrapper">
                        <div className="fatglobe-wrapper">
                          <img src={slide3.earthIcon} />
                        </div>
                        <span className="medium">{slide3.milesOrLabel}</span>
                      </div>
                    </div>
                    <div
                      className="hongkong"
                      style={{
                        background: `url(${slide3.hongkongImage})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                      }}
                    />
                    <div className="factoid narrow overdots">
                    <span className="large">{slide3.miles0Bottom}</span></div>
                  </>
                )}
                {Number(slide3.miles) > 0 && (
                  <>
                    <span className="medium">{slide3.miles1PlusTop}</span>
                    <div className="factoid">
                      <span className="vlarge">{parseInt(slide3.miles).toLocaleString()}</span>
                      <span className="medium">{slide3.miles1PlusUnit}</span>
                    </div>
                    <div>
                      <div className="or-wrapper">
                        <div className="fatglobe-wrapper">
                          <img src={slide3.earthIcon} />
                        </div>
                        <span className="medium">{slide3.milesOrLabel}</span>
                      </div>
                    </div>
                    <div className="factoid narrow">
                      <span className="large">
                        {slide3.miles1PlusGlobeCount} {slide3.miles1PlusBottom}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div
                id="SLIDE_4"
                className={`slide slide_4 ${
                  scrollPosition > 16400 && scrollPosition < 19355
                    ? "sticky"
                    : scrollPosition > 19355
                    ? "scrolledpast"
                    : ""
                }`}
              >
                <img src={slide3.earthIcon} />
                <span className="large wrap pbup">
                  {slide4.cloudTopLine1} {slide4.cloudTopLine2}
                </span>
                <div className="cloud-wrapper-wrapper">
                  <span className="xlarge">{slide4.cloudDays}</span>
                </div>
                <span className="medium pbup">{slide4.cloudUnit}</span>
                <span className="medium">{slide4.cloudBottom}</span>
              </div>
              <div
                id="SLIDE_5"
                className={`slide slide_5 ${
                  scrollPosition > 20145 && scrollPosition < 23305
                    ? "sticky"
                    : scrollPosition > 23305
                    ? "scrolledpast"
                    : ""
                } `}
              >
                <img className="takeoff" src={slide5.landIcon} />
                <span className="medium setWidth wrap">{slide5.tripTop}</span>
                {slide5.cities.map((c) => (
                  <div key={c} className="underline">
                    <span className="large">{c}</span>
                  </div>
                ))}
              </div>
              <div
                id="SLIDE_6"
                className={`slide slide_6 ${
                  scrollPosition > 24095 && scrollPosition < 27650
                    ? "sticky"
                    : scrollPosition > 27650
                    ? "scrolledpast"
                    : ""
                }`}
              >
                <span className="medium">{slide6.bankedMilesTopLine1}</span>
                <span className="medium">{slide6.bankedMilesTopLine2}</span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={slide6.asiaMilesIcon} />
                  <span className="xlarge">{slide6.asiaMilesEarned}</span>
                </div>
                <span className="medium">{slide6.bankedMilesBottom}</span>
              </div>
              <div
                id="SLIDE_7"
                className={`slide slide_7 ${
                  scrollPosition > 28045 && scrollPosition < 31205
                    ? "sticky"
                    : scrollPosition > 31205
                    ? "scrolledpast"
                    : ""
                }`}
              >
                <div className="content-div">
                  <span className="medium">{slide7.favWaysTop}</span>
                  {slide7.ways.map((w) => (
                    <div key={w} className="underline">
                      <span className="large">{w}</span>
                    </div>
                  ))}
                  <span>{slide7.favWaysBottom}</span>
                  </div>
              </div>
              <div
                id="SLIDE_8"
                className={`slide slide_8
                  ${scrollPosition > 33000 ? "sticky" : ""}`}
              >
                <div
                  className={`plane-wrapper ${
                    scrollPosition > 34000 ? "visible" : ""
                  }`}
                >
                  <img src={slide8.airplaneIcon} />
                </div>
                <span className="medium setWidth wrap">{slide8.endLabel}</span>
                <div className="factoid">
                  <span className="xlarge">{slide8.endYear}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="membership-wrapper">
        <div
          className={`membership ${
            scrollPosition < 2550 && scrollPosition > 900 ? "slideout" : ""
          }`}
        >
          {general.membershipTier === "GR" && (
            <img src={general.greenMemberImgUrl} />
          )}
          {general.membershipTier === "SL" && (
            <img src={general.silverMemberImgUrl} />
          )}
          {general.membershipTier === "GO" && (
            <img src={general.goldMemberImgUrl} />
          )}
          {general.membershipTier === "DM" && (
            <img src={general.diamondMemberImgUrl} />
          )}
          <span>
            {general.membershipTier === "DM"
              ? "Diamond Member"
              : general.membershipTier === "GO"
              ? "Gold Member"
              : general.membershipTier === "SL"
              ? "Silver Member"
              : "Green Member"}
          </span>
        </div>
      </div> */}
      <div className="progress-bar-wrapper">

        {scrollPosition < 6000 && (
           <div className="disclaimer">{slide0.disclaimer}</div>
        )}
      {scrollPosition < 6000 && (
        <div
          className={`desktop-only control lang ${
            languageModalIsOpen ? "selected" : ""
          }`}
          tabIndex="0"
          role="button"
          // onClick={() => setLanguageModal(!languageModal)}
          onClick={() => setlanguageModalIsOpen(true)}
        >
          {!languageModalIsOpen && <img src={general.languageIcon} />}
          {languageModalIsOpen && <img src={general.languageIconSelected} />}
        </div>
         )}

        {scrollPosition > 6000 && (<div
          className="share-journey-div"
          // style={{display:'none'}}
          // onClick={() => screenshotsTypeTwo()}
        >
          <button
            type="button"
            className="control"
            onClick={() => screenshotsTypeTwo()}
          >
           {shareJourney.mobileShareButtonLabel} <span> <img src={shareJourney.shareButtonIcon} /></span>
          </button>
        </div>
        )}

        {scrollPosition > 6000 && (
          <div className="desktop-only control link" tabIndex="0" role="link" onClick={copyToClip}>
            <img src={general.shareIcon} />
          </div>
        )}

 
      </div> 

      {/* {languageModal && (
        <div className="language-select-wrapper">
          {general.languageList.map((l) => (
            <span key={l.languageCode}>{l.languageLabel}</span>
          ))}
        </div>
      )} */}

      <div
        className="duringPrintingLoader"
        style={{ display: printing ? "block" : "none" }}
      >
        <div className="loading">
          <div className="loading-spinner-outer">
            <div className="loading-spinner-inner" />
          </div>
          <p>Preparing Your Journey's Export....</p>
        </div>
      </div>

      <div
        id="PRINT_JOURNEY"
        className={`print-journey ${printing ? "printing" : ""}`}
        style={{
          display: !printing ? "none" : "",
          //  background: `url(${general.cloudParallaxTwoImgUrl})`,
          backgroundImage: `url(${bgImage})`,
        }}
        // style={{top: `calc(100vh + 100px)`, zIndex:9999}}
      >
        <div className="window-frame-wrapper">
          <img className="logo" src={general.cathayLogo} />
          <img className="windowTop" src={slide0.backgroundImage} />
          {general.membershipTier === "GR" && (
            <img className="frame" src={general.windowFrameGreenImgUrl} />
          )}
          {general.membershipTier === "SL" && (
            <img className="frame" src={general.windowFrameSilverImgUrl} />
          )}
          {general.membershipTier === "GO" && (
            <img className="frame" src={general.windowFrameGoldImgUrl} />
          )}
          {general.membershipTier === "DM" && (
            <img className="frame" src={general.windowFrameDiamondImgUrl} />
          )}

          {/* <div
          id="SLIDE_0_PRINT"
          ref={printRef}
          className={`slide slide_0 loaded active`}
        >
          <span className="medium">{general.year}</span>
          <span className="medium vnarrow wrap">
            NEED CORS {slide0.landingTop}
            <br />
          </span>
          <span className="large vvnarrow wrap">{slide0.landingTitle}</span>
          <span className="small wrap">{slide0.landingScrollLabel}</span>
          <div className="arrowanim">
                <img src={slide0.arrowDownIcon}  />
              </div>
        </div> */}
          <div id="SLIDE_1_PRINT" className={`slide slide_1 active`}>
            <span className="large">{slide1.memberYearDescription}</span>
            <div className="split-flap">
              {/* <FlapDisplay
              className="XL"
              chars={Presets.NUM}
              length={slide1.memberYearCount.length}
              value={
                scrollPosition > 4345 * factor
                  ? slide1.memberYearCount.toString()
                  : "0"
              }
              timing={30}
              hinge
            /> */}
              <span className="xlarge">
                {slide1.memberYearCount.toString()}
              </span>
              <span className="sf-medium">
                {slide1.memberYearUnit}
                {slide1.memberYearUnit === "year" ? "s" : ""}
              </span>
            </div>
          </div>
          <div id="SLIDE_2_PRINT" className={`slide slide_2`}>
            <>
              <img src={slide2.flightIcon} />
              <span className="large">{slide2.flightTopLine1}</span>
              <span className="large">{slide2.flightTopLine2}</span>
              <div className="factoid col">
                <span className="xlarge">{slide2.flightTimes}</span>
                <span className="sf-medium">{slide2.flightUnit}</span>
                {Number(slide3.miles) === 0 && (
                  <span className="sf-medium">{slide2.flightBottom}</span>
                )}
              </div>
              <div className="runway-wrapper">
                <img className="road" src={slide2.roadIcon} />
                <img className="divider" src={slide2.roadDividerIcon} />
              </div>
            </>
          </div>
          <div id="SLIDE_3_PRINT" className={`slide slide_3`}>
            {Number(slide3.miles) === 0 && (
              <>
                <img src={slide3.earthIcon} />
                <span className="medium">{slide3.miles0Top}</span>
                <div className="factoid">
                  <span className="large">{slide3.miles0Fact}</span>
                </div>
                <div>
                  <div className="or-wrapper">
                    <div className="fatglobe-wrapper">
                      <img src={slide3.earthIcon} />
                    </div>
                    <span className="medium">{slide3.milesOrLabel}</span>
                  </div>
                </div>
                <div
                  className="hongkong"
                  style={{
                    background: `url(${slide3.hongkongImage})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                />
                <div className="factoid overdots">
                  <span className="large">{slide3.miles0Bottom}</span>
                </div>
              </>
            )}
            {Number(slide3.miles) > 0 && (
              <>
                <span className="medium">{slide3.miles1PlusTop}</span>
                <div className="factoid">
                  <span className="vlarge">{parseInt(slide3.miles).toLocaleString()}</span>
                  <span className="medium">{slide3.miles1PlusUnit}</span>
                </div>
                <div>
                  <div className="or-wrapper">
                    <div className="fatglobe-wrapper">
                      <img src={slide3.earthIcon} />
                    </div>
                    <span className="medium">{slide3.milesOrLabel}</span>
                  </div>
                </div>
                <div className="factoid narrow">
                  <span className="large">
                    {slide3.miles1PlusGlobeCount} {slide3.miles1PlusBottom}
                  </span>
                </div>
              </>
            )}
          </div>
          <div id="SLIDE_4_PRINT" className={`slide slide_4`}>
            <img src={slide3.earthIcon} />
            <span className="large wrap pbup">
              {slide4.cloudTopLine1} {slide4.cloudTopLine2}
            </span>
            <div className="cloud-wrapper-wrapper">
              <div className="cloud-wrapper">
                <img src={slide4.cloudIcon} />
              </div>
              <span className="xlarge">{slide4.cloudDays}</span>
            </div>
            <span className="medium pbup">{slide4.cloudUnit}</span>
            <span className="medium">{slide4.cloudBottom}</span>
          </div>
          <div id="SLIDE_5_PRINT" className={`slide slide_5 `}>
            <img className="takeoff" src={slide5.landIcon} />
            <span className="medium setWidth wrap">{slide5.tripTop}</span>
            {slide5.cities.map((c) => (
              <div key={c} className="underline">
                <span className="large">{c}</span>
              </div>
            ))}
          </div>
          <div id="SLIDE_6_PRINT" className={`slide slide_6`}>
            <span className="medium">{slide6.bankedMilesTopLine1}</span>
            <span className="medium">{slide6.bankedMilesTopLine2}</span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={slide6.asiaMilesIcon} />
              <span className="xlarge">{slide6.asiaMilesEarned}</span>
            </div>
            <span className="medium">{slide6.bankedMilesBottom}</span>
          </div>
          <div id="SLIDE_7_PRINT" className={`slide slide_7`}>
            <div className="content-div">

              <span className="medium">{slide7.favWaysTop}</span>
              {slide7.ways.map((w) => (
                <div key={w} className="underline">
                  <span className="large">{w}</span>
                </div>
              ))}
              <span>{slide7.favWaysBottom}</span>
            </div>
          </div>
          <div id="SLIDE_8_PRINT" className={`slide slide_8`}>
            <div className="plane-wrapper">
              <img src={slide8.airplaneIcon} />
            </div>
            <span className="medium setWidth wrap">{slide8.endLabel}</span>
            <div className="factoid">
              <span className="xlarge">{slide8.endYear}</span>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        <div className="modalContent shareJourneyContent">
          <div className="modalHeader">
            <button className="closeModal" onClick={closeModal}>
            <img src={shareJourney.closeIcon} />
            </button>
            <p>{shareJourney.shareTitle}</p>
          </div>

          <div className="modalBody">
            {generatedSlideImages.map((item, index) => (
              // <li key={index}>{item}</li>
              <div className="slideImageItem" key={index}>
                <label>
                  <input
                    type="checkbox"
                    className="itemCheckbox"
                    checked={selectedSlideImages.includes(index)}
                    onChange={(event) => handleCheckboxChange(index, event)}
                  />
                  <img className="slideImage" src={URL.createObjectURL(item)} />
                </label>
              </div>
            ))}
          </div>

          <div className="modalFooter">
            <button
              type="button"
              className="shareYourJourney"
              onClick={shareYourJourneyButton}
              disabled={selectedSlideImages.length < 1}
            >
              {isMobile && navigator.canShare && (
                <>
                {shareJourney.mobileShareButtonLabel} <span><img src={shareJourney.shareButtonIconWhite} /></span>
                </>
              )}
              {!isMobile && (
                shareJourney.desktopShareButtonLabel
              )}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={languageModalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeLanguageModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modalContent">
          <div className="modalHeader">
            <button className="closeModal language" onClick={closeLanguageModal}>
              <img src={shareJourney.closeIcon} />
            </button>
          </div>

          <div className="modalBody language">
          <div className="language-select-wrapper">
          <div className="iconLanguage"><img className="icon" src={general.languageIconSelected}/></div>
              {general.languageList.map((l,indx) => (
                <a href={l.languageUrl} key={l.languageCode} data-current={general.currentLanguage} 
                className={general.currentLanguage === l.languageCode ? 'active' : ''}>{l.languageLabel}</a>
              ))}
           </div>
          </div>

          <div className="modalFooter">
          </div>
        </div>
      </Modal>

      {/* {showToast && <div className="customToast">
            <h1>Toast Notification Example</h1>

        </div> } */}

        {showToast &&  <div className="customToast" onClick={() => setShowToast(false)}>
            <p>{toastMessage}</p>
        </div> }


    <div 
      className={`portraitAnimationLayer 
        ${ !isPortrait ? "show" : "hide"}`}
      >
        <div className="content-inside">
          <div className="icon-container">
            <img src={general.portraitLandscapeRotateIcon} />
          </div>
        </div>
    </div>


    <div
        className="initialOverlayLoader"
        style={{ display: !loaded ? "block" : "none" }}
      >
        <div className="loading">
          <div className="loading-spinner-outer">
            <div className="loading-spinner-inner" />
          </div>
          {/* <p>Loading experience....</p> */}
        </div>
      </div>

    </>

  );
}

export default YearInReviewAnimation;
